import { createTheme, responsiveFontSizes } from '@mui/material'


import { typography } from './typography'
import { components } from './components'
import { darkPalette, lightPalette } from './palette'


const goSchoolDarkTheme = createTheme({
  palette: darkPalette,
  typography
})

const goSchoolLightTheme = createTheme({
  palette: lightPalette,
  typography,
  components
})

const paletteTheme = goSchoolDarkTheme
export const goSchoolTheme = responsiveFontSizes(
  createTheme(
    goSchoolDarkTheme,
    {
      components: {
        MuiToolbar: {
          styleOverrides: {
            root: {
              '& .MuiIconButton-root': {
                color: paletteTheme.palette.primary.main
              }
            }
          }
        }
      }
    }
  )
)
